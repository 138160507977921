/* eslint react/prop-types: 0, react/display-name: 0 */
import { LocaleProvider } from "@hellodarwin/core/lib/features/providers/locale-provider";
import { TranslationsProvider } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { ThemeProvider } from "@hellodarwin/core/lib/plugins/styled";
import { GlobalTypographyStyling } from "@hellodarwin/core/lib/theme/global-style";
import ConfigProvider from "antd/es/config-provider";
import { GatsbyBrowser } from "gatsby";
import React from "react";
import PaginationProvider from "./src/context/PaginationProvider";
import { RootProvider } from "./src/context/RootProvider";
import URLParamProvider from "./src/context/URLParamProvider";
import getAntdTheme from "./src/utils/styles/antd-theme";
import GlobalStyle from "./src/utils/styles/global";
import theme from "./src/utils/styles/theme";

const WrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  const antdTheme = getAntdTheme(theme);
  return (
    <div id="root">
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <LocaleProvider>
            <RootProvider>
              <TranslationsProvider>
                <PaginationProvider>
                  <URLParamProvider>
                    <GlobalStyle />
                    <GlobalTypographyStyling />
                    {element}
                  </URLParamProvider>
                </PaginationProvider>
              </TranslationsProvider>
            </RootProvider>
          </LocaleProvider>
        </ConfigProvider>
      </ThemeProvider>
    </div>
  );
};

export default WrapRootElement;

